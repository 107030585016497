/* Fuentes personalizadas */
@font-face {
  font-family: "MonumentExtended";
  src: url("./assets/fonts/MonumentExtended-Regular.otf");
}

@font-face {
  font-family: "MonumentExtendedBold";
  src: url("./assets/fonts/MonumentExtended-Ultrabold.otf");
}

.bold {
  font-family: "MonumentExtendedBold";
}

.fontRegular {
  font-family: "Lato", sans-serif;
  font-style: normal;
}

#root{
  height: 100%!important;
}

.fontItalic {
  font-family: "Lato", sans-serif;
  font-style: italic;
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  /* height: 100vh; */
  background: white;
}

